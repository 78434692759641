exports.components = {
  "component---src-pages-about-megarise-js": () => import("./../../../src/pages/about-megarise.js" /* webpackChunkName: "component---src-pages-about-megarise-js" */),
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility-statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-blacklist-firms-js": () => import("./../../../src/pages/blacklist-firms.js" /* webpackChunkName: "component---src-pages-blacklist-firms-js" */),
  "component---src-pages-complaint-grievance-js": () => import("./../../../src/pages/complaint-grievance.js" /* webpackChunkName: "component---src-pages-complaint-grievance-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-ganol-catchment-unit-js": () => import("./../../../src/pages/ganol-catchment-unit.js" /* webpackChunkName: "component---src-pages-ganol-catchment-unit-js" */),
  "component---src-pages-gis-dashboard-js": () => import("./../../../src/pages/gis-dashboard.js" /* webpackChunkName: "component---src-pages-gis-dashboard-js" */),
  "component---src-pages-implementation-structure-js": () => import("./../../../src/pages/implementation-structure.js" /* webpackChunkName: "component---src-pages-implementation-structure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-pdf-display-js": () => import("./../../../src/pages/pdf-display.js" /* webpackChunkName: "component---src-pages-pdf-display-js" */),
  "component---src-pages-photos-ganol-catchment-js": () => import("./../../../src/pages/photos-ganol-catchment.js" /* webpackChunkName: "component---src-pages-photos-ganol-catchment-js" */),
  "component---src-pages-photos-umiew-catchment-js": () => import("./../../../src/pages/photos-umiew-catchment.js" /* webpackChunkName: "component---src-pages-photos-umiew-catchment-js" */),
  "component---src-pages-procurement-complaint-js": () => import("./../../../src/pages/procurement-complaint.js" /* webpackChunkName: "component---src-pages-procurement-complaint-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-tender-js": () => import("./../../../src/pages/tender.js" /* webpackChunkName: "component---src-pages-tender-js" */),
  "component---src-pages-terms-and-condition-js": () => import("./../../../src/pages/terms-and-condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */),
  "component---src-pages-training-materials-js": () => import("./../../../src/pages/training-materials.js" /* webpackChunkName: "component---src-pages-training-materials-js" */),
  "component---src-pages-umiew-catchment-unit-js": () => import("./../../../src/pages/umiew-catchment-unit.js" /* webpackChunkName: "component---src-pages-umiew-catchment-unit-js" */),
  "component---src-pages-website-policy-js": () => import("./../../../src/pages/website-policy.js" /* webpackChunkName: "component---src-pages-website-policy-js" */),
  "component---src-pages-whos-who-js": () => import("./../../../src/pages/who's-who.js" /* webpackChunkName: "component---src-pages-whos-who-js" */),
  "component---src-templates-display-document-js": () => import("./../../../src/templates/display_document.js" /* webpackChunkName: "component---src-templates-display-document-js" */),
  "component---src-templates-notification-display-js": () => import("./../../../src/templates/notification_display.js" /* webpackChunkName: "component---src-templates-notification-display-js" */)
}

